<template>
  <main class="py-4">
    <div class="container">
      <h1>登録企業一覧</h1>
      <div class="mb-2">
        <router-link to="/company" class="btn btn-primary"
          >新規追加</router-link
        >
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-bordered table-sm">
          <thead>
            <tr class="table-info">
              <th style="width: 14%">利用企業コード</th>
              <th style="width: 40%">企業名</th>
              <!-- th style="width: 10%" align="right">認証件数</th -->
              <th style="width: 46%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in results" :key="item.usecompanycd">
              <td><router-link :to="`company0001/?usecompanycd=${item.usecompanycd}`">{{ item.usecompanycd }}</router-link></td>
              <td>{{ item.companynm }}</td>
              <!-- td align="right">{{ item.sum_certcnt }}</td -->
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  title: "登録企業一覧画面",
  name: "inquiry-company",
  components: {},
  data(){
    return {
      items:   [],                                  // webAPIから取得したデータ
      results: []                                   // itemsから抽出したデータ
    }
  },
  created(){
    //const url = 'http://localhost:8080/api/MenuList'
    const url = process.env.VUE_APP_ROOT_API_SVRCERT + '/MenuList'
    const vue = this;   // Important
    const option = {responseType: "blob"};
    this.axios.get(url, option).then(res=>{
      res.data.text().then(str=>{vue.init(str);});
      if (res.status == 500) {
// ★★★：Internal Server Error
        // response.status == 500
        // Internal Server Error
        console.log(res);
        alert('Internal Server Error');
      }
    }).catch((e) => {
// ★★★：エラーメッセージ
      console.log(e);
      alert(e.response.status + ':' + e.message);
    });

    // ブラウザバックの制御
    history.pushState(null, window.location.title, window.location.href);
    window.addEventListener('popstate', (e) => {
      console.log(e);
      history.go(1);
    });
  },
  methods:{
    init(str){
      this.items = JSON.parse(str).data;      // JSONオブジェクトに変換
      this.reset();
    },
    reset(){
      this.results = this.items;              // resultsにコピー
    }
  }
};
</script>
<style lang="scss">
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
}
table th {
  text-align: center;
}
</style>
