var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar navbar-expand-md navbar-light bg-white shadow-sm sticky-top"},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse justify-content-between",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
                active:
                  (this.$route.path === '/menulist')
                  | (this.$route.path === '/company')
                  | (this.$route.path === '/company0001')
              },attrs:{"to":"/menulist"}},[_c('h4',[_vm._v("利用企業登録")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
                active: this.$route.path === '/inquiry_company'
              },attrs:{"to":"/inquiry_company"}},[_c('h4',[_vm._v("認証依頼件数照会")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
                active: this.$route.path === '/inquiry_trouble'
              },attrs:{"to":"/inquiry_trouble"}},[_c('h4',[_vm._v("迷惑電話照会")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
                active:
                  (this.$route.path === '/loginlist')
                  | (this.$route.path === '/loginuser')
                  | (this.$route.path === '/loginuser0001')
              },attrs:{"to":"/loginlist"}},[_c('h4',[_vm._v("ログイン情報登録")])])],1)]),_c('ul',{staticClass:"navbar-nav ml-auto"},[_c('form',[_c('div',{staticClass:"form-group row mb-0 width"},[_c('div',{staticClass:"col-md-8"},[_c('button',{staticClass:"btn text-nowrap btn-outline-secondary",attrs:{"name":"logout","type":"submit"},on:{"click":_vm.logout}},[_vm._v(" ログアウト ")])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"navbar-brand"},[_c('img',{attrs:{"src":require("../../public/assets/images/e_others_170.png"),"alt":"mock_Certification"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])}]

export { render, staticRenderFns }