import Vue from 'vue'
import VueRouter from 'vue-router'
import InquiryCompany from '../views/inquiry_company/inquiry_company'
import InquiryTrouble from '../views/inquiry_trouble/inquiry_trouble'
import Loginlist from '../views/loginlist/loginlist'
import Menulist from '../views/menulist/menulist'
import Company0001 from '../views/company/company0001'
import Login from '../views/login/login'
import Loginuser from '../views/loginuser/loginuser'
import Loginuser0001 from '../views/loginuser/loginuser0001'
import Company from '../views/company/company'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/inquiry_company',
    name: 'inquiry_company',
    component: InquiryCompany
  },
  {
    path: '/inquiry_trouble',
    name: 'inquiry_trouble',
    component: InquiryTrouble
  },
  {
    path: '/loginlist',
    name: 'loginlist',
    component: Loginlist
  },
  {
    path: '/loginuser',
    name: 'loginuser',
    component: Loginuser
  },
  {
    path: '/menulist',
    name: 'menulist',
    component: Menulist
  },
  {
    path: '/company0001',
    name: 'company0001',
    component: Company0001
  },
  {
    path: '/loginuser0001',
    name: 'loginuser0001',
    component: Loginuser0001
  },
  {
    path: '/company',
    name: 'company',
    component: Company
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
