<template>
  <main class="py-4">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">ログイン</div>
            <div class="card-body">
              <form @submit.prevent="login" >
                <input
                  type="hidden"
                  name="_token"
                  value="ZR8w77Kld0JRgJcuKQ6DxHsIcQmNUpT60cwsNepO"
                />
                <div class="form-group row">
                  <label
                    for="name"
                    class="col-md-4 col-form-label text-md-right"
                    style="text-align: right"
                    >ID</label
                  >
                  <div class="col-md-6">
                    <input
                      id="name"
                      type="text"
                      class="form-control"
                      name="name"
                      value=""
                      ref="input_name"
                      required
                      autofocus
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="password"
                    class="col-md-4 col-form-label text-md-right"
                    style="text-align: right"
                    >パスワード</label
                  >
                  <div class="col-md-6">
                    <input
                      id="password"
                      type="password"
                      class="form-control"
                      name="password"
                      ref="input_password"
                      required
                      autocomplete="current-password"
                    />
                  </div>
                </div>
                
                <div class="form-group row mb-0">
                  <div class="col-md-8 offset-md-4">
                    <button
                      name="login"
                      type="submit"
                      class="btn btn-primary"
                    >
                      ログイン
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Modal：警告メッセージ -->
    <div class="modal fade" id="collateModal" tabindex="-1" ref="modal_collate">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="collateModalLabel">ログイン</h5>
            <button type="button" class="close" @click="modalClose()">✕</button>
          </div>
          <div class="modal-body">ID、パスワードが一致しません。確認してください。</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="modalClose()">OK</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Modal } from 'bootstrap';
export default {
  title: '認証サーバログイン画面',
  name: 'login',
  components: {},
  created(){
    // ブラウザバックの制御
    history.pushState(null, window.location.title, window.location.href);
    window.addEventListener('popstate', (e) => {
      console.log(e);
      history.go(1);
    });
  },
  methods: {
    modalClose: function () {
      let elements = document.getElementsByTagName("div");
      Array.prototype.forEach.call(elements, function (element) {
          element.classList.remove("modal-open");
      });
      this.$delete(this.modeModal, 'modal-backdrop');
      this.modeModal.hide();
      
      this.$refs.input_name.value = '';
      this.$refs.input_password.value = '';
      document.getElementById("name").focus();
    },
    login: function () {
      //const url = 'http://localhost:8080/api/Login' + '/' + this.$refs.input_name.value + '/' + this.$refs.input_password.value 
      const url = process.env.VUE_APP_ROOT_API_SVRCERT + '/Login' + '/' + this.$refs.input_name.value + '/' + this.$refs.input_password.value 
      this.axios.get(url)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.data.length == 1) {
              // ログイン成功 -> 画面遷移「登録企業一覧画面」
              this.$router.push({ name: 'menulist' });
            } else {
              // 該当するデータが無い場合
              this.modeModal = new Modal(document.getElementById('collateModal'), {keyboard: true})
              this.modeModal.show();
              //this.$refs.input_name.value = '';
              //this.$refs.input_password.value = '';
              //document.getElementById("name").focus();
            }
          } else {
// ★★★：Internal Server Error
            // response.status == 500
            // Internal Server Error
            console.log(response);
            alert('Internal Server Error');
          } 
        })
        .catch((e) => {
// ★★★：エラーメッセージ
          console.log(e);
          alert(e.response.status + ':' + e.message);
        });
    },
  }
}
</script>