<template>
  <main class="py-4">
    <div class="container">
      <h1>認証依頼件数</h1>
      <div class="mb-2">
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#outputModal">ファイル出力（CSV）</button>
      </div>

      <div class="table-responsive">
        <table class="table table-striped table-bordered table-sm">
          <thead>
            <tr class="table-info">
              <th style="width: 14%">利用企業コード</th>
              <th style="width: 40%">企業名</th>
              <th style="width: 10%" align="right">{{ colMon_before }}月（前月）</th>
              <th style="width: 10%" align="right">{{ colMon }}月（当月）</th>
              <th style="width: 26%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in results" :key="item.usecompanycd">
              <td>{{ item.usecompanycd }}</td>
              <td>{{ item.companynm }}</td>
              <td align="right">{{ item.certcnt_before }}</td>
              <td align="right">{{ item.certcnt }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
    <!-- Modal -->
    <div class="modal fade" id="outputModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="outputModalLabel">ファイル出力（CSV）</h5>
            <button type="button" class="close" data-bs-dismiss="modal">✕</button>
          </div>
          <div class="modal-body">
            ファイル（CSV）を出力します。よろしいですか？
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">cancel</button>
            <button type="button" class="btn btn-primary" v-on:click="downloadCSV()" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  title: "企業別認証件数照会画面",
  name: "inquiry-company",
  components: {},
  data(){
    return {
      colMon_before: '',
      colMon: '',
      items:   [],                                  // webAPIから取得したデータ
      results: []                                   // itemsから抽出したデータ
    }
  },
  created(){
    var today = new Date();
    this.colMon_before = ('0' + (today.getMonth())).slice(-2);
    if ((today.getMonth() + 1) <= 12) {             // getMonth()：0 ～ 11
      this.colMon = ('0' + (today.getMonth() + 1)).slice(-2);
    } else {
      this.colMon = '01';
    }
    
    //const url = 'http://localhost:8080/api/InquiryCompany?month=' + this.colMon;
    const url = process.env.VUE_APP_ROOT_API_SVRCERT + '/InquiryCompany?month=' + this.colMon;
    const vue = this;   // Important
    const option = {responseType: "blob"};
    this.axios.get(url, option).then(res=>{
      res.data.text().then(str=>{vue.init(str);});
      if (res.status == 500) {
// ★★★：Internal Server Error
        // response.status == 500
        // Internal Server Error
        console.log(res);
        alert('Internal Server Error');
      }
    }).catch((e) => {
// ★★★：エラーメッセージ
      console.log(e);
      alert(e.response.status + ':' + e.message);
    });
    
    // ブラウザバックの制御
    history.pushState(null, window.location.title, window.location.href);
    window.addEventListener('popstate', (e) => {
      console.log(e);
      history.go(1);
    });
  },
  methods:{
    downloadCSV () {
      var csv = '\ufeff' + '利用企業コード,企業名,' + this.colMon_before + '月（前月）,' + this.colMon + '月（当月） \n'
      this.results.forEach(el => {
        var line = el['usecompanycd'] + ',' + el['companynm'] + ',' + el['certcnt_before'] + ',' + el['certcnt'] + '\n'
        csv += line
      })
      let blob = new Blob([csv], { type: 'text/csv' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'inquiry_company.csv'
      link.click()
    },
    init(str){
      this.items = JSON.parse(str).data;      // JSONオブジェクトに変換
      this.reset();
    },
    reset(){
      this.results = this.items;              // resultsにコピー
    }
  }
};
</script>
