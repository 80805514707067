<template>
  <main class="py-4">
    <div class="container">
      <h1>迷惑電話照会</h1>
      <div class="mb-2">
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#outputModal">ファイル出力（CSV）</button>
      </div>

      <div class="table-responsive" v-cloak>
        <table class="table table-striped table-bordered table-sm">
          <thead>
            <tr class="table-info">
              <th style="width: 20%">電話番号</th>
              <th style="width: 20%">着信日時</th>
              <!-- !!! 2022/06/10 表示項目に「件数」を追加-->
              <th style="width: 10%">件数</th>
              <th style="width: 20%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in results" :key="item.telno">
              <td>{{ item.telno }}</td>
              <td>{{ formatDatetime(item.arrivaldatetime) }}</td>
              <!-- !!! 2022/06/10 表示項目に「件数」を追加-->
              <td>{{ item.telnocnt }}</td>
              <td>
                <button class="btn btn-danger" v-on:click="deleteTelnoSet(item.telno)" data-bs-toggle="modal" data-bs-target="#deleteModal">削除</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
    <!-- Modal：ファイル出力（CSV） -->
    <div class="modal fade" id="outputModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="outputModalLabel">ファイル出力（CSV）</h5>
            <button type="button" class="close" data-bs-dismiss="modal">✕</button>
          </div>
          <div class="modal-body">
            ファイル（CSV）を出力します。よろしいですか？
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">cancel</button>
            <button type="button" class="btn btn-primary" v-on:click="downloadCSV()" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal：削除 -->
    <div class="modal fade" id="deleteModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">削除確認</h5>
            <button type="button" class="close" data-bs-dismiss="modal">✕</button>
          </div>
          <div class="modal-body">
            <p>削除します。よろしいですか？</p>
            <p>（電話番号：{{ deleteTelno }}）</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">cancel</button>
            <button type="submit" class="btn btn-primary" v-on:click="deleteRow()" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  title: "迷惑電話照会画面",
  name: "inquiry-trouble",
  components: {},
  data(){
    return {
      deleteTelno: '',
      items:   [],                                  // webAPIから取得したデータ
      results: []                                   // itemsから抽出したデータ
    }
  },
  created(){
    //const url = 'http://localhost:8080/api/InquiryTrouble';
    const url = process.env.VUE_APP_ROOT_API_SVRCERT + '/InquiryTrouble';
    const vue = this;   // Important
    const option = {responseType: "blob"};
    this.axios.get(url, option).then(res=>{
      res.data.text().then(str=>{vue.init(str);});
      if (res.status == 500) {
// ★★★：Internal Server Error
        // response.status == 500
        // Internal Server Error
        console.log(res);
        alert('Internal Server Error');
      }
    }).catch((e) => {
// ★★★：エラーメッセージ
      console.log(e);
      alert(e.response.status + ':' + e.message);
    });
    
    // ブラウザバックの制御
    history.pushState(null, window.location.title, window.location.href);
    window.addEventListener('popstate', (e) => {
      console.log(e);
      history.go(1);
    });
  },
  methods:{
    deleteTelnoSet(telno) {
      this.deleteTelno = telno;
    },
    formatDatetime(fromDatetime) {
      var dumyText = fromDatetime.substr(0,4) + '/' + fromDatetime.substr(4,2) + '/' + fromDatetime.substr(6,2)
                    + ' ' + fromDatetime.substr(8,2) + ':' + fromDatetime.substr(10,2) + ':' + fromDatetime.substr(12,2);
      return dumyText;
    },
    /* ファイル出力（CSV） */
    downloadCSV () {
      // !!! 2022/06/10 出力項目に「件数」を追加
      //var csv = '\ufeff' + '電話番号,着信日時\n'
      var csv = '\ufeff' + '電話番号,着信日時,件数\n'
      this.results.forEach(el => {
        var line = el['telno'] + ',' + this.formatDatetime(el['arrivaldatetime'])  + ',' + el['telnocnt'] + '\n'
        csv += line
      })
      let blob = new Blob([csv], { type: 'text/csv' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'inquiry_trouble.csv'
      link.click()
    },
    /* 削除処理 */
    deleteRow : function(){
      //const url = 'http://localhost:8080/api/InquiryTroubleDelete/' + this.deleteTelno;
      const url = process.env.VUE_APP_ROOT_API_SVRCERT + '/InquiryTroubleDelete/' + this.deleteTelno;
      const vue = this;   // Important
      const option = {responseType: "blob"};
      this.axios.delete(url, option).then(res=> {
        res.data.text().then(str=>{vue.init(str);});
        if (res.status == 500) {
// ★★★：Internal Server Error
          // response.status == 500
          // Internal Server Error
          console.log(res);
          alert('Internal Server Error');
        } else {
          // 再描画「迷惑電話照会画面」
          location.reload(true);
        }
      }).catch((e) => {
// ★★★：エラーメッセージ
        console.log(e);
        alert(e.response.status + ':' + e.message);
      })
    },
    init(str){
      this.items = JSON.parse(str).data;      // JSONオブジェクトに変換
      this.reset();
    },
    reset(){
      this.results = this.items;              // resultsにコピー
    }
  }
};
</script>
<style lang="scss">
v-cloak {
  display: none;
}
</style>